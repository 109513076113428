.form-check-input:checked {
	background-color: $bright-blue;
	border-color: $bright-blue;
}

.form-check-input:not([role="switch"])[type="checkbox"] {
	height: 16px;
	width: 16px;
}

.input-group-prepend .input-group-text {
	padding: 1px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $grey-400;
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

text {
	cursor: default;
	color: $grey-800;
}

.modal-backdrop {
	opacity: 0.2 !important;
}

.modal-content {
	.dropdown-menu {
		border: none;
	}

	.list-item {
		border: 2px solid $grey-200;
		border-radius: 16px;

		&:hover {
			background-color: $grey-200;

			.form-check-input {
				background-color: $grey-200;
			}
		}

		.form-check-input {
			border: 2px solid $bright-blue;
			
			height: 1.5em;
			width: 1.5em;

			&.selected {
				background-color: $bright-blue !important;
				background: url('~/public/images/icons/check-white.svg') no-repeat center center;
			}
		}
	}
}
.react-tooltip {
  z-index: 100;
  border-radius: 6px !important;
  text-align: left !important;
  padding: 8px !important;
  padding-left: 16px !important;
  opacity: 1 !important;

  &:not(.tooltip-m):not(.tooltip-sm) {
    width: 245px !important;
  }

  &.tooltip-m {
    width: 160px !important;
  }

  &.tooltip-sm {
    min-width: 80px !important;
  }
}

.legend-tooltip {
  word-wrap: break-word;
  white-space: break-spaces;
}
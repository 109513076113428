.chart-overlay {
    position: relative;
    margin-top: -28px;
    height: 20px;
}

.chart-tooltip-lg {
    width: 400px;
}

.chart-tooltip-md {
    width: 260px;
}

@media (max-width: 992px) {
    div:has(> .chart-tooltip-lg), .chart-tooltip-lg {
        position: absolute;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 320px;
    }

    div:has(> .chart-tooltip-md), .chart-tooltip-md {
        position: absolute;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
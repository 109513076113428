body {
    font-family: 'Source Sans Pro', sans-serif;
}


@media (max-width: 768px) {

    body,
    div,
    span,
    a,
    button {
        font-size: 0.875rem;
    }
}


h1 {
    font-size: 2rem;
    font-weight: 700;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
}

h6 {
    margin-bottom: 0;
}

.fw-semi-bold {
    font-weight: 600;
}

.text-sm {
    font-size: 0.75rem;
}

.text-md {
    font-size: 0.875rem;
}

.lh-100 {
    line-height: 100%;
}
.nav-tabs {
    border-bottom: 1px solid $grey-200;

    .nav-item {
        border: none;

        .nav-link {
            color: $grey-800;
            border: none;
            border-radius: 0;
            box-shadow: none;

            &.active {
                color: $dark-blue;
                border-bottom: 2px solid $dark-blue;
                font-weight: 600;
            }
        }
    }
}
.panel-item {
    cursor: pointer;
}

.panel-item:hover {
    background-color: $grey-200;
    color: $bright-blue;
}

.panel-item.selected {
    background-color: $grey-200;
}

.panel-item.checked {
    background-color: $grey-200;
}

.panel-item {
    .item-name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
}

@media (min-width: 992px) {
    .overlay {
        width: $overlay-width;
        min-width: $overlay-width;
    }
}

@media (max-width: 992px) {
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: white;
        z-index: 10;
    }
}

.rotate-90 {
    transform: rotate(-90deg);
    margin-bottom: 5px;
}
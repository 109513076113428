.form-check {
    min-height: auto;
}

.form-check-input[type='checkbox'].checkbox {
    height: 14px;
    width: 14px;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

input[type='checkbox'] {
    accent-color: $bright-blue;
}
.dropdown {
    .dropdown-toggle {
        width: 100%;
        color: $dark-blue;
        background-color: $white;
        border: 1.5px solid $grey-200;
    }

    .dropdown-menu {
        width: 100%;
        border: 1.5px solid $grey-200;
        background-color: $white;
        color: $black-100;
        border-radius: 8px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);

        .dropdown-item:hover {
            background-color: $grey-200;
            border-radius: 8px;
        }
    }

    &.show {
        .dropdown-arrow {
            transform: rotate(180deg);
        }
    }

    .dropdown-toggle::after {
        display: none;
    }
}

.dropdown-search-clear-icon.gray {
    display: none;
}

.dropdown-heading-dropdown-arrow.gray {
    color: $black-100;
}

.rmsc {
    .dropdown-container {
        border: 1.5px solid $grey-200;
        cursor: pointer;
    }

    .dropdown-container:focus-within {
        box-shadow: none;
        border: 1.5px solid $grey-200;
    }

    .dropdown-heading {
        cursor: pointer;
    }

    .dropdown-content {
        padding-top: 0px;

        .panel-content {
            box-shadow: none;
            border: 1.5px solid $grey-200;
            border-radius: 0 0 8px 8px;
        }
    }

    .select-item {
        &.selected {
            background-color: $grey-200;
        }
    }
}

.custom-dropdown {
    position: relative;
    display: inline-block;

    .dropdown-toggle {
        cursor: pointer;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-menu {
        margin-left: -45px;
        display: none;
        position: absolute;
        border: none;
        background-color: $white;
        color: $black-100;
        border-radius: 8px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    }

    .dropdown-menu li {
        cursor: pointer;
    }

    .dropdown-menu li:hover {
        background-color: $grey-200;
        border-radius: 8px;
    }

    &.open .dropdown-menu {
        display: block;
    }

    .dropdown-item.active {
        background-color: $white;
        color: $black-100;
    }
}

@media (max-width: 576px) {
    .dropdown-menu-width {
        width: 360px;
    }
}

@media (min-width: 576px) {
    .dropdown-menu-width {
        width: 400px;
    }
}

.open-accordion {
    transform: rotate(180deg);
}
.dcm-input {
    font-size: 0.75rem;
    width: 100%;
    padding: 6px;
    border: 1.5px solid $grey-200;
    border-radius: 4px;
    outline: none;
    background: url(../../../public/images/icons/search.svg) no-repeat scroll 7px 7px;
    padding-left: 30px;

}

.dcm-input:hover {
    border: 1.5px solid rgba($bright-blue, .5);
}

.dcm-input:focus,
.dcm-input:focus-visible {
    border: 1px solid $bright-blue;
}
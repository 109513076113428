.box-shadow {
    box-shadow: 0px 0px 28px 0px rgba(26, 38, 63, 0.10);
}

.border-grey-200 {
    border-color: $grey-200;
}

.border-radius {
    border-radius: 16px;
}

.rounded-md {
    border-radius: 24px;
}

.rounded-large {
    border-radius: 32px;
}

.border-top-grey-200 {
    border-top: 1px solid $grey-200;
}



.border-thick-top-grey-200,
.border-top {
    border-top: 1.5px solid $grey-200 !important;
}

.border-bottom-grey-200 {
    border-bottom: 1px solid $grey-200;
}

.border-bottom-grey-400 {
    border-bottom: 1px solid $grey-400;
}

.border-top-grey-400 {
    border-top: 1px solid $grey-400;
}

.line {
    background-color: $grey-200;
    height: 0.5px;
}
.activities-container {
    .activities__control {
        border: 1.5px solid $grey-200;
        border-radius: 8px;
        color: $dark-blue;
        box-shadow: none;

        &:hover {
            border: 1.5px solid $grey-200;
        }

        .activities__value-container {
            padding: 8px 16px;
            display: block;
        }

        .activities__input-container {
            width: 1px !important;
            opacity: 0 !important;
            position: fixed;

            .activities__input {
                width: 1px !important;
                opacity: 0 !important;
            }
        }

        .activities__indicator-separator {
            display: none;
        }
    }

    .activities__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .activities__dropdown-indicator {
            transform: rotate(180deg);
        }
    }

    .activities__option {
        background-color: white;

        &:hover,
        :active {
            background-color: $grey-200 !important;
        }

        &.disabled {
            color: $grey-600;
            pointer-events: none;

            input[type='checkbox'] {
                border: 1.5px solid $grey-400;
            }
        }

        &.activities__option--is-selected {
            background-color: $grey-200;
            color: $dark-blue;
            font-weight: 600;

            &:hover {
                background-color: $grey-200;
            }
        }
    }

    .activities__menu {
        padding: 8px 16px;
        margin-top: 0;
        border-radius: 8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: none;
        border: 1.5px solid $grey-200;
        border-top: none;
    }

    .activities__menu-list {
        overflow-x: hidden;
    }

    .activities__group {
        border-bottom: 2px solid $grey-200;

        .activities__group-heading {
            font-size: 1rem;
            text-transform: capitalize;
            font-weight: 600;
            color: $dark-blue;
        }
    }

    .activities__placeholder {
        display: none;
    }

    .activities__multi-value {
        display: none;
    }
}
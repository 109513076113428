footer a {
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
    color: $bright-blue;
}

.footer-bottom {
    clear: both;
    position: relative;
    height: 60px;
    margin-top: -60px;
}
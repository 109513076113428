.btn-regular {
    width: 100%;
    border-radius: 12px;
    border: none;
    color: $white;
    background-color: $bright-blue;

    &:hover {
        background-color: $dark-blue;
    }

    &:active {
        color: $white;
        background-color: $bright-blue;
    }

    &:disabled,
    &[disabled] {
        background-color: $grey-400;
        color: $grey-800;
    }
}

.btn-transparent {
    background-color: transparent;
    border: none;
    padding: 8px 16px;

    &:hover {
        border-radius: 100px;
        background-color: $grey-200;
    }

    &:disabled {
        cursor: pointer;
    }
}